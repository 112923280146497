<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="table"
          title="사업장 목록"
          tableId="plant"
          :columns="grid.columns"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
                  <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <c-card title="사업장 정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                      <c-btn
                  v-if="saveEditable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="plant"
                  mappingType="PUT"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-select
                  :comboItems="companyItems"
                  type="edit"
                  itemText="companyName"
                  itemValue="mdmCompanyId"
                  name="attrVal1"
                  label="회사"
                  v-model="plant.attrVal1"
                ></c-select>
              </div>
              <div class="col-6">
                <c-text
                  :required="true"
                  :disabled="!saveMode"
                  :editable="editable"
                  label="소재지"
                  name="attrVal2"
                  v-model="plant.attrVal2">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :disabled="!saveMode"
                  :editable="editable"
                  label="시설구분"
                  name="attrVal3"
                  v-model="plant.attrVal3">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'plant-manage',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'companyName',
            field: 'companyName',
            // 회사명
            label: '회사명',
            align: 'left',
            style: 'width:35%',
            sortable: false,
          },
          {
            name: 'codeName',
            field: 'codeName',
            // 사업장명
            label: '사업장명',
            align: 'left',
            style: 'width:35%',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            // 사용여부
            label: '사용여부',
            align: 'center',
            style: 'width:30%',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        useFlag: 'Y',
      },
      plant: {
        codeGrpCd: '',  // 코드그룹
        code: '',  // 코드
        codeName: '',  // 코드명
        codeNameEn: '',  // 코드명-영어
        codeNameJa: '',  // 코드명-일어
        codeNameZhCn: '',  // 코드명-중국어간체
        codeNameZhTw: '',  // 코드명-중국어번체
        attrVal1: null,  // 추가속성1
        attrVal2: '',  // 추가속성2
        attrVal3: '',  // 추가속성3
        attrVal4: '',  // 추가속성4
        description: '',  // 비고
        useFlag: 'Y',  // 사용여부
        sortOrder: '',  //
      },
      useFlagItems: [],
      companyItems: [],
      editable: true,
      saveMode: false,
      isSave: false,
      listUrl: '',
      companyListUrl: '',
      detailUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    saveEditable() {
      return this.editable && this.saveMode
    }
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.plant.list.url;
      this.companyListUrl = selectConfig.mdm.company.list.url;
      this.detailUrl = selectConfig.mdm.plant.get.url;
      this.saveUrl = transactionConfig.mdm.plant.update.url;
      // code setting
      this.setCompany();
      // list scope
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    setCompany() {
      this.$http.url = this.companyListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',
      };
      this.$http.request((_result) => {
        this.companyItems = _result.data;
      },);
    },
    rowClick(row) {
      this.$http.url = this.$format(this.detailUrl, row.code);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.plant, _result.data);
        this.saveMode = true;
      },);
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.plant.code = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
      this.rowClick({ code: result.data });
    },
  }
};
</script>
